import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Address } from "../model/Address";
import { Company } from "../model/Company";
import { Warehouse } from "../model/Warehouse";
import { AddressService } from "./address.service";
import { ApiService } from "./api.service";
import { CompanyService } from "./company.service";
import { baseResponseObject } from "../configs/request.config";

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  private url = '/warehouse';
  public warehouses: BehaviorSubject<Warehouse[]> = new BehaviorSubject<
    Warehouse[]
  >([]);
  public warehouseType: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  constructor(
    private api: ApiService,
    private addressService: AddressService,
    private companyService: CompanyService
  ) {
    this.getWarehouseTypes().subscribe((resp: any) =>{
      this.warehouseType.next(resp[baseResponseObject])
    });
  }

  centralMethod: { value: string; label: string }[] =
    this.createCentralMethod();

  createCentralMethod(): { value: string; label: string }[] {
    const language = 'pt'
    const unitOfMeasure = {

      pt: [
        { value: 'livre', label: 'Livre' },
        { value: 'alfena', label: 'Alfena' },
        { value: 'almada', label: 'Almada' },
        { value: 'andique', label: 'Andique' },
        { value: 'aveiro', label: 'Aveiro' },
        { value: 'campodeourique', label: 'Campo de Ourique' },
        { value: 'cascais', label: 'Cascais' },
        { value: 'ermesinde', label: 'Ermesinde' },
        { value: 'faial', label: 'Faial' },
        { value: 'guimaraes', label: 'Guimarães' },
        { value: 'lagoa', label: 'Lagoa' },
        { value: 'leiria', label: 'Leiria' },
        { value: 'lumiar', label: 'Lumiar' },
        { value: 'marinhagrande', label: 'Marinha Grande' },
        { value: 'matocooper', label: 'Matocooper' },
        { value: 'multitaxis', label: 'Multitáxis' },
        { value: 'politaxis', label: 'Politáxis' },
        { value: 'pontadelgada', label: 'PontaDelgada' },
        { value: 'pontinha', label: 'Pontinha' },
        { value: 'redistaxis', label: 'RédisTáxis' },
        { value: 'restelo', label: 'Restelo' },
        { value: 'ribeiragrande', label: 'Ribeira Grande' },
        { value: 'saojoaodamadeira', label: 'São João da Madeira' },
        { value: 'scaltaxis', label: 'ScalTáxis' },
        { value: 'seixal', label: 'Seixal' },
        { value: 'taxigon', label: 'Taxigon' },
        { value: 'taxintra', label: 'Táxintra' },
        { value: 'taxismadeira', label: 'TáxisMadeira' },
        { value: 'taxiscoope', label: 'TaxisCoope' },
        { value: 'viseu', label: 'Viseu' }      
      ],
    };
    return unitOfMeasure[language];
  }


  public getFiltered(
    companyId: string,
    minLimit,
    maxLimit,
    orderedList?,
    textFilter?,
    filter_type?
  ) {
    const url = '/warehouses_filtered';
    const formData = new FormData();
    formData.append('company_id', companyId);

    // return this.api.post(url, formData, true, false)
    return this.api
      .getFiltered(
        url,
        formData,
        minLimit,
        maxLimit,
        orderedList,
        textFilter,
        filter_type
      )
      .pipe(
        map((resp: any) => {
          return {
            warehouses_number: resp[baseResponseObject].warehouses_number,
            warehouses: resp[baseResponseObject].warehouses.map((c) =>
              this.createWarehouseFromRequest(c)
            ),
          };
        })
      );
  }

  public newEmptyWarehouse(): Warehouse {
    return {
      activated: false,
      address: this.addressService.newEmptyAddress(),
      code: '',
      company: this.companyService.newEmptyCompany(),
      createdDate: '',
      description: '',
      warehouseId: '',
      name: '',
      series: '',
      shippingAddress: this.addressService.newEmptyAddress(),
      type: '',
      central:'',
      devId: '',
      registration: '',
      model: '',
      brand: '',
      city: '',
      year: '',

    };
  }

  private createString(str: string): string {
    if (!str) return '';
    return str;
  }

  private createDateTime(timestamp: number) {
    const minLength = 12;
    //if (!timestamp) return new Date(0);
    if ((timestamp + '').length < minLength) {
      const d=new Date(timestamp * 1000);
      return `${d.getDate()}/${(d.getMonth()+1)}/${d.getFullYear()}`;
    }
    //return new Date(timestamp);
  }

  // WAREHOUSE CREATE PARAMETER FUNCTIONS

  private _createActivated(warehouseInfo: any): boolean {
    if (!warehouseInfo.activated) return false;
    return warehouseInfo.activated;
  }
  private _createAddress(warehouseInfo: any): Address {
    if (!warehouseInfo.address) return this.addressService.newEmptyAddress();
    return this.addressService.createAddressFromRequest(warehouseInfo.address);
  }
  private _createCode(warehouseInfo: any): string {
    return this.createString(warehouseInfo.code);
  }
  private _createCompany({ company }: any): Company {
    if (!company) return this.companyService.newEmptyCompany();
    return this.companyService.createCompanyFromRequest(company);
  }
  private _createCreatedDate(warehouseInfo: any): string {
    return this.createDateTime(warehouseInfo.created_date);
  }
  private _createDeletedDate(warehouseInfo: any): string {
    return this.createDateTime(warehouseInfo.deleted_date);
  }
  private _createDescription(warehouseInfo: any): string {
    return this.createString(warehouseInfo.description);
  }
  private _createWarehouseId(warehouseInfo: any): string {
    return this.createString(warehouseInfo.id);
  }
  private _createName(warehouseInfo: any): string {
    return this.createString(warehouseInfo.name);
  }
  private _createSeries(warehouseInfo: any): string {
    return this.createString(warehouseInfo.series);
  }
  private _createShippingAddress(warehouseInfo: any): Address {
    if (!warehouseInfo.shipping_address)
      return this.addressService.newEmptyAddress();
    return this.addressService.createAddressFromRequest(
      warehouseInfo.shipping_address
    );
  }
  private _createType(warehouseInfo: any): string {
    return this.createString(warehouseInfo.type);
  }
  private _createCentral(warehouseInfo: any): string {
    return this.createString(warehouseInfo.central);
  }
  private _createCarRegister(warehouseInfo: any): string {
    return this.createString(warehouseInfo.carRegister);
  }

  private _createDevId(warehouseInfo: any): string {
    return this.createString(warehouseInfo.dev_id);
  }

  private _createRegistration(warehouseInfo: any): string {
    return this.createString(warehouseInfo.registration);
  }

  private _createModel(warehouseInfo: any): string {
    return this.createString(warehouseInfo.model);
  }

  private _createBrand(warehouseInfo: any): string {
    return this.createString(warehouseInfo.brand);
  }

  private _createCity(warehouseInfo: any): string {
    return this.createString(warehouseInfo.city);
  }

    private _createYear(warehouseInfo: any): string {
    return this.createString(warehouseInfo.year);
  }

  public createWarehouseFromRequest(warehouseInfo: any): Warehouse {
    if (!warehouseInfo) return this.newEmptyWarehouse();

    const warehouse: Warehouse = {
      activated: this._createActivated(warehouseInfo),
      address: this._createAddress(warehouseInfo),
      code: this._createCode(warehouseInfo),
      createdDate: this._createCreatedDate(warehouseInfo),
      description: this._createDescription(warehouseInfo),
      warehouseId: this._createWarehouseId(warehouseInfo),
      name: this._createName(warehouseInfo),
      series: this._createSeries(warehouseInfo),
      shippingAddress: this._createShippingAddress(warehouseInfo),
      type: this._createType(warehouseInfo),
      central:this._createCentral(warehouseInfo),
      devId:this._createDevId(warehouseInfo),
      registration:this._createRegistration(warehouseInfo),
      model:this._createModel(warehouseInfo),
      brand:this._createBrand(warehouseInfo),
      city:this._createCity(warehouseInfo),
      year:this._createYear(warehouseInfo),

    };
    if (warehouseInfo.deleted_date)
      warehouse['deletedDate'] = this._createDeletedDate(warehouseInfo);

    if (warehouseInfo.company) {
      warehouse['company'] = this._createCompany(warehouseInfo);
    }

    return warehouse;
  }

  delete(warehouse: Warehouse) {
    const formData = new FormData();
    formData.append('warehouse_id', warehouse.warehouseId);

    return this.api.delete(this.url, formData, true);
  }

  getWarehouseTypes() {
    const url = '/warehouse_types';
    const formData = new FormData();

    return this.api.get(url, formData, true);
  }

  get(warehouse: Warehouse) {
    const formData = new FormData();
    formData.append('warehouse_id', warehouse.warehouseId);

    return this.api.get(this.url, formData, true);
  }

  getAll(): Observable<Warehouse[]> {
    if (!this.companyService.selectedCompany.value) return of([]);
 
    const company = this.companyService.selectedCompany.value;
 
    return this.getFiltered(company.companyId, 0, 5000).pipe(
       map((resp: any) => {
          const warehouses = resp.warehouses;
          this.warehouses.next(warehouses); // Update the BehaviorSubject
          return warehouses;
       })
    );
 }
  
      
    // return this.api.getAll('/warehouses', formData, true).pipe(
    //   map((resp: any) => {
    //     resp = resp[baseResponseObject]
    //       .filter((d) => d.deleted_date == null)
    //       .map((d: any) => this.createWarehouseFromRequest(d))
    //       .sort((a: Warehouse, b: Warehouse) => (a.name < b.name ? -1 : 1));
    //     this.warehouses.next(resp);
    //     return resp;
    //   })
    // );
  

  createWarehouseFormData(warehouse) {
    const formData = new FormData();
    formData.append('name', warehouse.name.toUpperCase());
    formData.append('dev_id', warehouse.devId);
    formData.append('registration', warehouse.registration);
    formData.append('model', warehouse.model);
    formData.append('brand', warehouse.brand);
    formData.append('city', warehouse.city);
    formData.append('year', warehouse.year);
    formData.append('series', warehouse.series);
    formData.append('activated', 'true');
    if (warehouse.address.id && warehouse.address.id != '') {
      formData.append('address_id', warehouse.address.id);
    }
    formData.append('company_id', warehouse.company.companyId);
    if (warehouse.shippingAddress?.id && warehouse.shippingAddress.id != '') {
      formData.append('shipping_address_id', warehouse.shippingAddress.id);
    }
    formData.append('central', warehouse.central);
    // formData.append('type', warehouse.type);
    formData.append('type', 'TAXI');
    return formData;
  }

  post(warehouse: Warehouse) {
    const formData = this.createWarehouseFormData(warehouse);
    return this.api.post(this.url, formData, true);
  }

  put(warehouse: Warehouse) {
    const formData = this.createWarehouseFormData(warehouse);
    formData.append('activated', 'true');
    formData.append('warehouse_id', warehouse.warehouseId);
    return this.api.put(this.url, formData, true);
  }

  selectFunctionWarehouse(warehouse: Warehouse) {
    if (warehouse.warehouseId) {
      return this.put(warehouse);
    }

    return this.post(warehouse);
  }

  compareWarehouse(w1: Warehouse, w2: Warehouse): boolean {
    if (!w2) {
      return false;
    }
    return (
      w1.warehouseId == w2.warehouseId &&
      w1.name == w2.name &&
      w1.description == w2.description &&
      w1.series == w2.series &&
      this.addressService.compareAddress(w1.address, w2.address) &&
      this.addressService.compareAddress(w1.shippingAddress, w2.shippingAddress)
      // this.companyService.compareCompany(w1.company, w2.company)
    );
  }

  validateWarehouse(warehouse: Warehouse): boolean {
    return (
      warehouse.name != '' &&
      warehouse.series != '' &&
      this.addressService.validateAddress(warehouse.address) &&
      // this.companyService.validateCompany(warehouse.company) &&
      warehouse.type != ''
    );
  }
}
